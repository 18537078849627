@tailwind base;
@tailwind components;
@tailwind utilities;

 #app-loader{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background: #16a34a;
}

*{
  border-radius:0 !important;
}
button {
  border-radius: 0 !important;
} 


.nav-link {
  @apply mx-1 md:border-0 border-b-2 px-6 py-4 font-semibold text-sm uppercase hover:bg-green-600 hover:text-white hover:rounded-lg hover:shadow-lg transition ease-in-out;
}

.active {
  @apply text-white bg-green-600 rounded-lg ;
}

#image-div, #escape {
  mask-image: linear-gradient(#000, rgba(0,0,0,-1));
}

@media (min-width: 1024px) {
    #image-div {
        mask-image: none;
    }
}

@media screen and (min-width: 763px)  {
  #nav-dropdown {
    display: none;
  }
}
@media (max-width: 1217px) {
  #show {
    display: none;
  }
}

#dropdown:hover > #dropper {
  display: block !important;
}

#dropper {
  display: none !important;
}

.mask1 {
  -webkit-mask-image: linear-gradient(black, transparent);
  mask-image: linear-gradient(black, transparent);
}

#image-set {
background: linear-gradient(63deg, rgba(2,0,36,1) 8%, rgba(22,24,33,1) 32%, rgba(75,91,94,0) 93%),
        url(../src/assets/images/construction-image3.jpg);
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
    }
#image-set1 {
background:
        /* top, transparent black, faked with gradient */ 
        linear-gradient(
          rgba(0, 0, 0, 0.7), 
          rgba(0, 0, 0, 0.7)
        ),
        /* bottom, image */
        url(../src/assets/images/bg-image.jpg);
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
    }
#image-set2 {
background:
        /* top, transparent black, faked with gradient */ 
        linear-gradient(
          rgba(0, 0, 0, 0.7), 
          rgba(0, 0, 0, 0.7)
        ),
        /* bottom, image */
        url(../src/assets/images/construction-bg.jpg);
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
    }

          #construction-wrapper {
          background: linear-gradient(63deg, rgba(2,0,36,1) 8%, rgba(22,24,33,1) 32%, rgba(75,91,94,0) 93%), url(../src/assets/images/construction-site.jpg);
          background-size: cover;
          background-position: center center;
          background-repeat: no-repeat;
    }


    /* @media (min-width: 768px) {
          #construction-wrapper {
          background: linear-gradient(63deg, rgba(2,0,36,1) 8%, rgba(22,24,33,1) 32%, rgba(75,91,94,0) 93%), url(../src/assets/images/construction-site.jpg);
          background-size: cover;
          background-position: center center;
          background-repeat: no-repeat;
    }

} */